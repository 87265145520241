import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { ReportsService } from './reports.service';

export class Field {
  Id?: number;
  FieldType?: string;
  FieldName: string;
  DisplayName?: string;
  IsPartOfField?: boolean;
  RelatedField?: number;
  Length?: number;
  Value: any;
  IsStatic = false;
}

export class WrapupAndSaveModel {
  Fields: Array<Field> = [];

  fkProblem: number;
  fkIssue: number;
  fkLane: number;
  fkLot: number;

  HangupTime: Date;
  CallDuration: number;
  WrapupTime: Date;
  WrapupDuration: number;

  AgentSIP: string;
  AgentName: string;
  Caller: string;
  CallId: string;
  CallSource: string;

  GateVend = false;
  MgrNotified = false;
  SessionId: string = null;
  InQueue: number = 0;
  // Operator: number;
  // UserId: number;
}

export class Receipt {
  CustomerName: string;
  CustomerEmail: string;
  CashCreditAmount = 0.0;
  ParkingLocation: string;
  DateOfTransaction = new Date();
  Address: string;
  City: string;
  StateProvince: string;
  ZipCode: string;
  Phone: string;
  SecondEmail: string;
  LogoFile: string;

  constructor(parkingLocation: string) {
    this.ParkingLocation = parkingLocation;
  }
}

export interface IUpdateNotes {
  IncidentId: number;
  Notes: string;
  AgentName: string;
  OldNotes: string;
  SendAlert: boolean;
}

@Injectable({ providedIn: 'root' })
export class IncidentService {
  constructor(
    private _httpClient: HttpClient,
    private _reports: ReportsService,
  ) {}

  getHeatmap(HeatmapFilter): Observable<any> {
    return this._reports.getHeatmap(HeatmapFilter || {});
  }

  get(incidentId): Observable<any> {
    return this._httpClient.post<any>('Facility/Incident/' + incidentId, null);
  }

  wrapupAndSave(model: WrapupAndSaveModel): Observable<any> {
    return this._httpClient.post<any>('Incident/WrapupAndSave', model);
  }

  sendReceipt(model: Receipt): Observable<any> {
    return this._httpClient.post<any>('Incident/SendReceipt', { Receipt: model });
  }

  updateNotes(model: IUpdateNotes): Observable<any> {
    return this._httpClient.post<any>('Incident/UpdateNotes', model);
  }
}
