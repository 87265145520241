export interface IGoogleTimeZone {
  TimeZoneId: string;
  TimeZoneName: string;
  TimeZoneIdAdaptive: string;
}

export const GOOGLE_TIMEZONE_LIST: IGoogleTimeZone[] = [
    { TimeZoneId: 'America/New_York', TimeZoneName: 'Eastern (most areas)', TimeZoneIdAdaptive: 'EST' },
    { TimeZoneId: 'America/Detroit', TimeZoneName: 'Eastern - MI (most areas)', TimeZoneIdAdaptive: 'EST' },
    { TimeZoneId: 'America/Kentucky/Louisville', TimeZoneName: 'Eastern - KY (Louisville area)', TimeZoneIdAdaptive: 'EST' },
    { TimeZoneId: 'America/Kentucky/Monticello', TimeZoneName: 'Eastern - KY (Wayne)', TimeZoneIdAdaptive: 'EST' },
    { TimeZoneId: 'America/Indiana/Indianapolis', TimeZoneName: 'Eastern - IN (most areas)', TimeZoneIdAdaptive: 'EST' },
    { TimeZoneId: 'America/Indiana/Vincennes', TimeZoneName: 'Eastern - IN (Da, Du, K, Mn)', TimeZoneIdAdaptive: 'EST' },
    { TimeZoneId: 'America/Indiana/Winamac', TimeZoneName: 'Eastern - IN (Pulaski)', TimeZoneIdAdaptive: 'EST' },
    { TimeZoneId: 'America/Indiana/Marengo', TimeZoneName: 'Eastern - IN (Crawford)', TimeZoneIdAdaptive: 'EST' },
    { TimeZoneId: 'America/Indiana/Petersburg', TimeZoneName: 'Eastern - IN (Pike)', TimeZoneIdAdaptive: 'EST' },
    { TimeZoneId: 'America/Indiana/Vevay', TimeZoneName: 'Eastern - IN (Switzerland)', TimeZoneIdAdaptive: 'EST' },
    { TimeZoneId: 'America/Chicago', TimeZoneName: 'Central (most areas)', TimeZoneIdAdaptive: 'CST' },
    { TimeZoneId: 'America/Indiana/Tell_City', TimeZoneName: 'Central - IN (Perry)', TimeZoneIdAdaptive: 'CST' },
    { TimeZoneId: 'America/Indiana/Knox', TimeZoneName: 'Central - IN (Starke)', TimeZoneIdAdaptive: 'CST' },
    { TimeZoneId: 'America/Menominee', TimeZoneName: 'Central - MI (Wisconsin border)', TimeZoneIdAdaptive: 'CST' },
    { TimeZoneId: 'America/North_Dakota/Center', TimeZoneName: 'Central - ND (Oliver)', TimeZoneIdAdaptive: 'CST' },
    { TimeZoneId: 'America/North_Dakota/New_Salem', TimeZoneName: 'Central - ND (Morton rural)', TimeZoneIdAdaptive: 'CST' },
    { TimeZoneId: 'America/North_Dakota/Beulah', TimeZoneName: 'Central - ND (Mercer)', TimeZoneIdAdaptive: 'CST' },
    { TimeZoneId: 'America/Denver', TimeZoneName: 'Mountain (most areas)', TimeZoneIdAdaptive: 'MST' },
    { TimeZoneId: 'America/Boise', TimeZoneName: 'Mountain - ID (south); OR (east)', TimeZoneIdAdaptive: 'MST' },
    { TimeZoneId: 'America/Phoenix', TimeZoneName: 'MST - AZ (except Navajo)', TimeZoneIdAdaptive: 'MST' },
    { TimeZoneId: 'America/Los_Angeles', TimeZoneName: 'Pacific', TimeZoneIdAdaptive: 'PST' },
    { TimeZoneId: 'America/Anchorage', TimeZoneName: 'Alaska (most areas)', TimeZoneIdAdaptive: 'AKST' },
    { TimeZoneId: 'America/Juneau', TimeZoneName: 'Alaska - Juneau area', TimeZoneIdAdaptive: 'AKST' },
    { TimeZoneId: 'America/Sitka', TimeZoneName: 'Alaska - Sitka area', TimeZoneIdAdaptive: 'AKST' },
    { TimeZoneId: 'America/Metlakatla', TimeZoneName: 'Alaska - Annette Island', TimeZoneIdAdaptive: 'AKST' },
    { TimeZoneId: 'America/Yakutat', TimeZoneName: 'Alaska - Yakutat', TimeZoneIdAdaptive: 'AKST' },
    { TimeZoneId: 'America/Nome', TimeZoneName: 'Alaska (west)', TimeZoneIdAdaptive: 'AKST' },
    { TimeZoneId: 'America/Adak', TimeZoneName: 'Alaska - western Aleutians', TimeZoneIdAdaptive: 'AKST' },
    { TimeZoneId: 'Pacific/Honolulu', TimeZoneName: 'Hawaii', TimeZoneIdAdaptive: 'HST' },
];

export const TIME_ZONES = [
  { Id: 'EST', Name: 'Eastern Standard Time' },
  { Id: 'CST', Name: 'Central Standard Time' },
  { Id: 'PST', Name: 'Pacific Standard Time' },
  { Id: 'MST', Name: 'Mountain Standard Time' },
  { Id: 'HST', Name: 'Hawaii-Aleutian Time' },
  { Id: 'AKST', Name: 'Alaska Standard Time' },
];
