import { ModuleWithProviders, NgModule, NgZone } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { SliderModule, NumericTextBoxModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

import { AuthService, AppStateService, LocationsService, ANALYTICS } from '@libs/portal-common/services';
import { SharedModule } from '@libs/portal-common/shared';
import { SystemModule } from '@libs/portal-common/system';
import { ValidationModule } from '@libs/portal-common/validation';
import { ActivityModule } from '@libs/portal-common/activity';

import { Five9Module } from '../integrations/five9';
import { FinesseModule } from '../integrations/cisco-finesse';
import { CC4AllModule } from '../integrations/cc4all';
import { NoIntegrationModule } from '../integrations/no-integration';

import { ISkypeAppServiceToken } from '../abstractions';

import { SkypeAppService } from './services';
import { SkypeSignedInGuard, IncomingCallGuard, IddleGuard } from './services';
import { IncomingCallsService } from './services';
import { IssueSubmissionService } from './services';
import { NavigationService } from './services';

import { CallProcessingComponent } from './call-processing/call-processing.component';

import { IncomingCallComponent } from './incoming-call/incoming-call.component';
import { LocationSelectorComponent } from './incoming-call/location-selector/location-selector.component';
import { LaneSelectorComponent } from './incoming-call/lane-selector/lane-selector.component';
import { CallProcessingFormComponent } from './call-processing/call-processing-form/call-processing-form.component';
import { ReceiptComponent } from './call-processing/call-processing-nav/receipt/receipt.component';

import { MePersonComponent } from './me-person/me-person.component';

import { ConversationManagementComponent } from './call-processing/call-processing-nav/conversation-management/conversation-management.component';

import { GeneralInformationComponent } from './call-processing/call-processing-info/general-information/general-information.component';
import { EquipmentComponent } from './call-processing/call-processing-info/equipment/equipment.component';
import { RateInformationComponent } from './call-processing/call-processing-info/rate-information/rate-information.component';
import { NotificationsBarComponent } from './call-processing/notifications-bar/notifications-bar.component';

import { CallProcessingNavComponent } from './call-processing/call-processing-nav/call-processing-nav.component';
import { MapComponent } from './call-processing/call-processing-nav/map/map.component';
import { DvrVideoComponent } from './call-processing/call-processing-nav/dvr-video/dvr-video.component';

import { CredentialsStorageService, APP_CONFIGURATION, AppConfigurationService } from '../services';
import { IntegrationAppFactoryService } from './services';

export function skypeAppFactory(
  auth: AuthService,
  credentialsStorage: CredentialsStorageService,
  appStateService: AppStateService,
  configuration: AppConfigurationService,
  ngZone: NgZone,
) {
  return new SkypeAppService(auth, credentialsStorage, appStateService, configuration, ngZone);
}

import { SkypeClientRoutingModule } from './skype-client-routing.module';
import { CommonIssuesComponent } from './call-processing/call-processing-info/common-issues/common-issues.component';
import { PictureDetailsComponent } from './call-processing/call-processing-info/equipment/picture-details/picture-details.component';
import { ReservationsListComponent } from './call-processing/call-processing-info/reservations-list/reservations-list.component';

import { SupportComponent } from './call-processing/call-processing-nav/support/support.component';

import { MediaCapabilitiesDetectionComponent } from './media-capabilities-detection/media-capabilities-detection.component';

import { SkypeConversationComponent } from './skype-conversation/skype-conversation.component';
import { ConversationParticipantComponent } from './skype-conversation/conversation-participant/conversation-participant.component';
import { ContactsManagerComponent } from './contacts-manager/contacts-manager.component';
import { SkypeGroupComponent } from './contacts-manager/skype-group/skype-group.component';
import { SkypeContactComponent } from './contacts-manager/skype-contact/skype-contact.component';

import { StartIncomingCallComponent } from './start-incoming-call/start-incoming-call.component';

// Revenue control
import { TicketInfoComponent } from './call-processing/revenue/ticket-info/ticket-info.component';
import { RevenueControlSelectorComponent } from './call-processing/revenue/revenue-control-selector/revenue-control-selector.component';

import { RevenueControlConfirmationComponent } from './call-processing/revenue/revenue-control-confirmation/revenue-control-confirmation.component';

import { RevenueFormFactoryService } from './call-processing/revenue/revenue-forms/revenue-form-factory.service';
import { RevenueControlFormComponent } from './call-processing/revenue/revenue-control-form/revenue-control-form.component';

import { OpenGateBaseComponent } from './call-processing/revenue/revenue-forms/base/open-gate-base/open-gate-base.component';
import { CloseGateBaseComponent } from './call-processing/revenue/revenue-forms/base/close-gate-base/close-gate-base.component';
import { EjectTicketBaseComponent } from './call-processing/revenue/revenue-forms/base/eject-ticket-base/eject-ticket-base.component';
import { SwallowTicketBaseComponent } from './call-processing/revenue/revenue-forms/base/swallow-ticket-base/swallow-ticket-base.component';
import { SetPriceBaseComponent } from './call-processing/revenue/revenue-forms/base/set-price-base/set-price-base.component';
import { LostTicketBaseComponent } from './call-processing/revenue/revenue-forms/base/lost-ticket-base/lost-ticket-base.component';
import { TicketInfoBaseComponent } from './call-processing/revenue/revenue-forms/base/ticket-info-base/ticket-info-base.component';
import { LostTicketFlashParcsComponent } from './call-processing/revenue/revenue-forms/flash-parcs/lost-ticket-flash-parcs/lost-ticket-flash-parcs.component';
import { TicketInfoFlashParcsComponent } from './call-processing/revenue/revenue-forms/flash-parcs/ticket-info-flash-parcs/ticket-info-flash-parcs.component';
import { GateInstructionsComponent } from './call-processing/call-processing-info/gate-instructions/gate-instructions.component';
import { CreateTicketModalComponent } from './call-processing/call-processing-info/create-ticket-modal/create-ticket-modal.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { TicketInfoTibaComponent } from './call-processing/revenue/revenue-forms/tiba/ticket-info-tiba/ticket-info-tiba.component';
import { ConvertSecsToDatePipe } from '../pipes/seconds-to-date.pipe';
import { CallProcessingService } from './services/call-processing.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    ModalModule,
    TooltipModule,
    AccordionModule,
    TabsModule,
    CarouselModule,
    ButtonsModule,

    GridModule,
    DropDownsModule,
    SliderModule,
    NumericTextBoxModule,
    SwitchModule,
    PopupModule,
    DateInputsModule,

    SharedModule,
    ActivityModule,
    SystemModule,
    ValidationModule,

    Five9Module,
    FinesseModule,
    CC4AllModule,
    NoIntegrationModule,

    SkypeClientRoutingModule,

    ReactiveFormsModule,
    GoogleMapsModule,
  ],
  declarations: [
    IncomingCallComponent,
    LocationSelectorComponent,
    LaneSelectorComponent,
    CallProcessingNavComponent,
    CallProcessingFormComponent,
    ReceiptComponent,
    SupportComponent,
    MePersonComponent,
    ConversationManagementComponent,
    RevenueControlSelectorComponent,
    RevenueControlConfirmationComponent,
    GeneralInformationComponent,
    MapComponent,
    EquipmentComponent,
    RateInformationComponent,
    DvrVideoComponent,
    NotificationsBarComponent,
    CallProcessingComponent,
    CommonIssuesComponent,
    PictureDetailsComponent,
    ReservationsListComponent,
    SkypeConversationComponent,
    ConversationParticipantComponent,
    ContactsManagerComponent,
    SkypeGroupComponent,
    SkypeContactComponent,
    MediaCapabilitiesDetectionComponent,
    TicketInfoComponent,
    StartIncomingCallComponent,

    RevenueControlFormComponent,
    OpenGateBaseComponent,
    CloseGateBaseComponent,
    EjectTicketBaseComponent,
    SwallowTicketBaseComponent,
    SetPriceBaseComponent,
    LostTicketBaseComponent,
    TicketInfoBaseComponent,
    LostTicketFlashParcsComponent,
    TicketInfoFlashParcsComponent,
    GateInstructionsComponent,
    CreateTicketModalComponent,
    ConvertSecsToDatePipe,
    TicketInfoTibaComponent,
  ],
  exports: [
    IncomingCallComponent,
    MePersonComponent,
    NotificationsBarComponent,
    SkypeConversationComponent,
    ContactsManagerComponent,
    MediaCapabilitiesDetectionComponent,
    RevenueControlSelectorComponent,
    RevenueControlConfirmationComponent,
    CreateTicketModalComponent,
  ],
})
export class SkypeClientModule {
  static forRoot(): ModuleWithProviders<SkypeClientModule> {
    return {
      ngModule: SkypeClientModule,
      providers: [
        {
          provide: ISkypeAppServiceToken,
          deps: [AuthService, CredentialsStorageService, AppStateService, APP_CONFIGURATION, NgZone],
          useFactory: skypeAppFactory,
        },

        SkypeSignedInGuard,
        IncomingCallGuard,
        IddleGuard,
        IncomingCallsService,
        IssueSubmissionService,
        NavigationService,
        IntegrationAppFactoryService,
        RevenueFormFactoryService,
        LocationsService,
        CallProcessingService,
      ],
    };
  }
}
