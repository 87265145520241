import { Inject, Injectable, OnInit } from '@angular/core';

import { init as amplitudeInit, track, identify, Identify, setUserId, getUserId } from '@amplitude/analytics-browser';

import { AnalyticsEvent, IAnalyticsService } from '@libs/portal-common';

import { AppConfigurationService, APP_CONFIGURATION } from '../services/app-configuration.service';
import { NavigationService } from '../skype-client/services';

export enum AgentAnalyticsEvent {
  ParkonectTabOpen = 'parkonect_open',
  LocationSelected = 'location_select',
  LaneSelected = 'lane_select',
  HangUp = 'hangup_click',
  WrapUp = 'submit_incident_click',
  DtmfClick = 'dtmf_click',
  PauseClick = 'hold_click',
  ResumeClick = 'resume_click',
  VendGateClicked = 'vend_click',
  GeneralInfoClicked = 'home_click',
  RateInfoClicked = 'rate_info_click',
  CommonIssuesClicked = 'issues_click',
  PicturesClicked = 'pictures_click',

  MapClicked = 'map_click',
  CamClicked = 'cam_click',

  CreateTicketOpen = 'create_ticket_open',
  CreateTicketSaved = 'create_ticket_saved',

  RevenueFormOpen = 'rc_open',
  RevenueFormExecute = 'rc_action',

  Exception = 'agent_error',

  GenesysInitSuccessfully = 'GNS_initialization_successfully',
  GenesysUserLogged = 'GNS_user_logged',
  GenesysUserFailedAuth = 'GNS_user_failed_auth',
  GenesysSetAwayStatus = 'GNS_set_away_status',
  GenesysSetReadyStatus = 'GNS_set_ready_status',
  GenesysSetBusyStatus = 'GNS_set_busy_status',
  GenesysCallHangUp = 'GNS_call_hangup',
  GenesysCallWrapUp = 'GNS_call_wrapup',
  GenesysCallAccepted = 'GNS_call_accepted',
  GenesysCallFinished = 'GNS_call_finished',
  GenesysCallInbound = 'GNS_call_inbound',
  GenesysCallDialing = 'GNS_call_dialing',
  GenesysCallConnected = 'GNS_call_connected',
  GenesysCallDisconnected = 'GNS_call_disconnected',
  GenesysErrorPatchConversation = 'GNS_error_patch_conversation',

}

export enum ReportName {
  IncidentsLocation = 'IncidentsLocation',
  IncidentsMonth = 'IncidentsMonth',
  LocationsVolume = 'LocationsVolume',
  AgentMetrics = 'AgentMetrics',
  CallStats = 'CallStats',
  DataExport = 'DataExport',
}

@Injectable({ providedIn: 'root' })
export class AnalyticsService implements IAnalyticsService {
  private callId: string | undefined;
  private callerId: string | undefined;

  constructor(
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService,
    private navigationService: NavigationService,
  ) {
    navigationService.incomingCall$.subscribe((x) => {
      this.callId = x?.conversation?.callId;
      this.callerId = x?.conversation?.callerUri;
    });
  }

  initialize() {
    amplitudeInit(this.configuration.data.amplitudeAnalytics.apiKey);
    this.init(this.configuration.data.applicationOptions.companyName);
  }

  track(event: AnalyticsEvent | AgentAnalyticsEvent, data: any = null) {
    data = this.appendCallData(data);
    track(event, data);
  }

  trackLogin(userId: string) {
    this.setUserId(userId);
    track(AnalyticsEvent.Login);
  }

  setUserId(userId: string) {
    setUserId(userId);
    this.setEmail(userId);
  }

  private init(customer: string) {
    const identifyEvent = new Identify();
    identifyEvent.set('Customer', customer);
    identify(identifyEvent);
  }

  private setEmail(userId: string) {
    const identifyEvent = new Identify();
    identifyEvent.set('Email', userId);
    identify(identifyEvent);
  }

  private appendCallData(data: any): any {
    if (!this.callerId && !this.callId) {
      return data;
    }

    data = data || {};
    data['Caller_id'] = this.callerId;
    data['Call_id'] = this.callId;
    return data;
  }
}
