<kendo-dropdownlist
  [data]="filteredData"
  [(value)]="model"
  (filterChange)="handleFilter($event)"
  [textField]="textField"
  [valueField]="valueField"
  [valuePrimitive]="valuePrimitive"
  [defaultItem]="defaultItem"
  [disabled]="disabled"
  [filterable]="filterable"
  [itemDisabled]='itemDisabled'
  (selectionChange)="handleSelection($event)"
>
</kendo-dropdownlist>
