import { Component, OnInit, ViewChild, ViewChildren, QueryList, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { tap, map, filter, flatMap } from 'rxjs/operators';

import { ModalDirective } from 'ngx-bootstrap/modal';
import {
  AudioRecordsService,
  AppConfigurationService,
  APP_CONFIGURATION,
  IAppConfigurationBase,
  VoiceBotsService,
} from '@libs/portal-common/services';
import { AudioPlayerComponent } from '@libs/portal-common/shared';
import { AppNotificationsService } from '@libs/portal-common/system';

class AudioRecord {
  Id: number;
  CallId: number;
  Caller: string;
  Duration: number;
  EndTime: Date;
  FileName: string;
  FileSize: number;
  StartTime: Date;
  fkIncident: number;

  url: string = null;
}

@Component({
  selector: 'app-audio-records',
  templateUrl: './audio-records.component.html',
  styleUrls: ['./audio-records.component.scss'],
})
export class AudioRecordsComponent implements OnInit {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @ViewChildren(AudioPlayerComponent) players: QueryList<AudioPlayerComponent>;

  incidentId: number;

  audioRecordsList = new Array<AudioRecord>();

  constructor(
    private sanitizer: DomSanitizer,
    @Inject(APP_CONFIGURATION) private configuration: AppConfigurationService<IAppConfigurationBase>,
    private audioRecordsService: AudioRecordsService,
    private notifications: AppNotificationsService,
    private voiceBotService: VoiceBotsService,
  ) {}

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnInit() {}

  open(incidentId: number) {
    this.incidentId = incidentId;

    this.initAudioRecords();

    this.modal.show();
  }

  openVBEventAudio(conferenceId: string) {
    this.audioRecordsList = [];

    this.voiceBotService.getBotEvent(conferenceId).subscribe((response) => {
      if (response.Success) {
        this.audioRecordsList.push(<AudioRecord>{
          url: `${this.configuration.apiBaseUrl}/BotEvent/${response.BotEvent.ConferenceId}/Record`,
          Caller: response.BotEvent.Caller,
        });
      }
    });

    this.modal.show();
  }

  cancel() {
    this.players.forEach((p) => p.pause());

    this.modal.hide();
  }

  private initAudioRecords() {
    this.audioRecordsList = [];

    this.audioRecordsService
      .getByIncident(this.incidentId)
      .pipe(
        map((response) => {
          if (response.Success === true) {
            return response.AudioRecords.map((record) => <AudioRecord>record);
          } else {
            this.notifications.error(response.Message);
            return [];
          }
        }),
      )
      .subscribe((audioRecords) => {
        this.audioRecordsList = audioRecords;
        this.audioRecordsList.forEach((record) => {
          record.url = `${this.configuration.apiBaseUrl}/AudioRecords/Download/${record.Id}`;
        });
      });
  }
}
